import React from "react"
import Helmet from "react-helmet"
import { graphql } from 'gatsby'
import Layout from "../components/layout"

const AboutPage = ({
  data: {
    site
  },
}) => {
  return (
    <Layout>
      <Helmet>
        <title>About — {site.siteMetadata.title}</title>
        <meta name="description" content={"About page of " + site.siteMetadata.description} />
      </Helmet>

      <div className="blog-post-container">
      <article className="post">
        <div className="post-thumbnail" style={{backgroundImage: `url('/assets/people-team-pulling-rope-piggy-bank.jpg')`, marginBottom: 0}}>
          <h1 className="post-title">About This Blog</h1>
          <h3><a href="#summary" style ={{color:'#fff'}}>What is this about?</a> ◆ <a href="#how" style ={{color:'#fff'}}>How does it work?</a> ◆ <a href="#faq" style ={{color:'#fff'}}>FAQ?</a> </h3>
        </div>
        <div className= "blog-post-content">
          <p></p>
          <br/>
          <a name ="summary" aria-label = "summary-anchor" href ="/#summary"></a>
          <h1>What is this about?</h1>
          <p>If you haven't heard of Referral Marketing (Referral schemes/Tell-a-friend/Refer-A-Friend) - <a href='https://www.springboard.com/blog/referral-marketing-what-it-is-and-why-it-works/'>see here</a> for a more in-depth explanation.</p>
          <p> Corporate businesses are really generous with their sign-up/referral bonuses and if navigated correctly, you could be making hundreds of pounds with a few hours a week.</p> 
          <a name ="how"></a>
          <h1>How does it work? </h1>
          <p>I've organized this blog into various categories of referral bonuses such as bonus type (Cash/Crypto/Discounts) and type of product. Each
             blog post contains a summary table with the offer, how long it typically takes and general requirements. Further down, I provide a step by step guide
             on how to fulfil the criteria on a bonus. 
             I will always link the T&Cs and let you determine your eligibility, and from time to time I will offer 
             promotions for using my referrals and return some of my bonus to you!</p>
          <a name ="faq"></a>
          <h1>FAQ</h1>
          <h4>What do I need to get started?</h4>
          <p>Here is a list of pre-requisites before you get started:
            <br/>
            <ul>
              <li>Email address - Really, you need this for pretty much much every online activity...</li>
              <li>Mobile Phone - Typically required for 2FA/MFA where they send you a text to verify your identity</li>
              <li>Bank Account - This is typically where your withdrawn bonuses will be paid into (Very important!)</li>
              <li>Foreign Bank Account - Some offers (like money transfers) require a foreign <a href="https://www.investopedia.com/terms/i/iban.asp">IBAN/BIC</a> code to your domestic account
            - if you don't have one, <a href="/bitwala/sign-up">Click here</a> to see if you're eligible for these services</li>
              <li>Proof of Identity - Passport, Driving License, ID card</li>
              <li>Proof of Address - Utility bills, bank statements</li>
            </ul>
          </p>
          <h4>What is an IBAN/SWIFT/BIC code?</h4>
          <p>You will see this a lot for the money transfer offers. IBAN stands for International Bank Account Number and is created by combining your normal 
            bank account number with your sort code and Bank country of origin. You can find yours <a href="https://www.iban.com/calculate-iban">here</a>. BIC and SWIFT are 
            essentially the same thing where BIC stands for Bank Identifier Code. Your bank will provide you their BIC for international transactions.
          </p>
          <h4>Why do I need to verify myself?</h4>
          <p>You will almost always need to verify an email address as these sign-up bonuses are geared towards individuals, and they will want you to prove you're not
            a bot or an existing customer in disguise. Some of the products here are strictly regulated financial services, you will notice some offers on this blog require deposits
            and money transfers - they are handling other peoples money (including mine and yours). This means they will have anti-money laundering policies in place, which
            is typically known as <a href="https://en.wikipedia.org/wiki/Know_your_customer">Know Your Customer (KYC)</a>. From a business perspective, it costs a lot for them
            and puts people off from sigining up, however its beneficial to them in the long run as it is a good shield against fraud and regulatory fines/lawsuits.
            It is always good to know your money is in the hands of a regulated business as well.
          </p>
          <h4>What will they do with my data?</h4>
          <p>Each service/product on this blog is slightly different, so please consult the Terms & Conditions of each page. Typically, they are 
            European or US/UK based businesses and as such will be regulated by governing bodies and laws such as <a href="https://www.fca.org.uk/about">FCA</a>, <a href="https://gdpr-info.eu/">GDPR</a>, <a href="https://www.fscs.org.uk/about-us/">FCSC</a> and more.
          </p>
        </div> 
        </article>
      </div>
    </Layout>
  )
}
export default AboutPage
export const aboutPageQuery = graphql`
  query AboutPageQuery{
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`